@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$ac-destruction: #ff1f1f;

dialog[open].modal  {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-height: 80vh;
        max-height: 80dvh;
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }
}

.modalContent {
    padding: 0;
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.listRoot {
    margin: 0;
    padding: 0;
    list-style: none;
}

.listItem {
    transition: all .2s ease-in-out;

    &:focus,
    &:hover {
        background-color: #f5fafa;
    }

    &:not(:first-child)::before {
        content: '';
        display: block;
        margin: 0 var(--spacing-core-4);
        height: 1px;
        background-color: #e0e0e0;
    }
}

.listIconAdd {
    margin-right: var(--spacing-core-4);
    border-radius: calc(var(--spacing-core-6) * 0.5);
    width: 56px;
    height: 56px;
    background: #131313;
    display: flex;
    align-items: center;
    justify-content: center;
}


.addListIcon {
    fill: #fff;
}

.spinner {
    padding: var(--spacing-core-4) var(--spacing-core-8);
    text-align: center;
}

.button {
    display: flex;
    flex-grow: 1;
    flex-flow: row nowrap;
    align-items: center;
    padding: var(--spacing-core-3) var(--spacing-core-6);
    width: 100%;
    margin: 1px 0;
    background-color: transparent;
    color: var(--color-foreground-primary-default);
    text-decoration: none;
    text-align: left;
    font-size: 16px;
    border-radius: 0;
    height: auto;
    justify-content: flex-start;

    &:hover {
        cursor: pointer;
    }
}

.buttonConfirm {
    width: 100%;
}

.text {
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    align-items: baseline;
    min-width: 0;
    margin-right: var(--spacing-core-2);
}

.label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.count {
    color: #acacac;
    margin-left: 0;
}

.defaultContentIcon,
.contentIcon {
    width: 56px;
    height: 56px;
    display: flex;
    flex-shrink: 0;
    flex-basis: auto;
    background: #e0e0e0;
    border-radius: calc(var(--spacing-core-6) * 0.5);
    margin-right: var(--spacing-core-4);
    padding-top: 0;
    object-fit: cover;
}

.noBorder {
    border: none;
}

.modalHeader {
    padding: var(--spacing-core-6);
    border-bottom: var(--border-width-core-regular) solid #e0e0e0;
}

.modalFooter {
    padding: var(--spacing-core-6);
    transition: box-shadow var(--motion-duration-core-medium);
    box-shadow: none;
}

.modalFooterShadow {
    box-shadow: 0 -4px 4px rgb(0 0 0 / 4%),0 -1px 2px rgb(0 0 0 / 3%);
}

.modalFooterWithError {
    padding-top: 0;
}

.errorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-core-4) var(--spacing-core-6);
    margin: 0 calc(var(--spacing-core-6) * -1);
    gap: var(--spacing-core-4);
    background: #f3f6f8
}

.errorIcon {
    fill: $ac-destruction;
}

.errorMessage {
    font-weight: normal;
}

.recipeCreated {
    display: flex;
    align-items: center;
    padding: var(--spacing-core-4);
    flex-direction: column
}

.checkbox {
    display: flex;
    margin-left: auto;
    box-sizing: content-box;
}
