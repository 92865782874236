@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

dialog[open].modal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }
}

.button {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    padding: 0 var(--spacing-core-6);
    flex-grow: 1;
}

.modalFooter {
    padding: var(--spacing-core-6);
}

